<template>
  <form @submit.prevent="updateProfile">
    <!-- Profile section -->
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div>
        <h2 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("Profile") }}
        </h2>
      </div>

      <div class="mt-6 grid grid-cols-12 gap-6 items-center">
        <div class="col-span-12 sm:col-span-6">
          <label for="land" class="block text-sm font-medium text-gray-700">{{
            $t("Contry")
          }}</label>
          <select
            id="land"
            name="land"
            v-model="user.land"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(land, i) in lands" :key="i" :value="land">
              {{ $t(land) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6" v-if="user.land == 'Italien'">
          <label
            for="province"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("Province") }}</label
          >
          <select
            id="province"
            name="province"
            v-model="user.province"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(province, i) in provinces"
              :key="i"
              :value="province.sigla"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="alter" class="block text-sm font-medium text-gray-700">{{
            $t("age")
          }}</label>
          <select
            id="alter"
            name="alter"
            v-model="user.alter"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(alter, i) in alterList" :key="i" :value="alter.id">
              {{ $t(alter.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="person" class="block text-sm font-medium text-gray-700">{{
            $t("People in your household (including yourself)")
          }}</label>
          <select
            id="person"
            name="person"
            v-model="user.person"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(person, i) in personList"
              :key="i"
              :value="person.id"
            >
              {{ $t(person.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="person_a" class="block text-sm font-medium text-gray-700"
            >{{ $t("Individuals with their own income") }}
          </label>
          <select
            id="person_a"
            name="person_a"
            v-model="user.person_a"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(person, i) in personList"
              :key="i"
              :value="person.id"
            >
              {{ $t(person.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label
            for="children"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("children") }}</label
          >
          <select
            id="children"
            name="children"
            v-model="user.children"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option
              v-for="(child, i) in childrenList"
              :key="i"
              :value="child.id"
            >
              {{ $t(child.name) }}
            </option>
          </select>
        </div>

        <div class="col-span-12 sm:col-span-6">
          <label for="job" class="block text-sm font-medium text-gray-700">{{
            $t("Occupation of main earner (s)")
          }}</label>
          <select
            id="job"
            name="job"
            v-model="user.job"
            class="
              mt-1
              block
              w-full
              pl-3
              pr-10
              py-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
              rounded-md
            "
          >
            <option v-for="(job, i) in OccupationList" :key="i" :value="job.id">
              {{ $t(job.name) }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label class="block text-sm font-medium text-gray-700">{{
            $t("Username")
          }}</label>
          <input
            type="text"
            v-model="user.username"
            class="
              mt-1
              block
              w-full
              border border-gray-300
              rounded-md
              shadow-sm
              py-2
              px-3
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
            "
          />
        </div>
      </div>
    </div>

    <div class="pt-4">
      <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
        <button
          type="submit"
          class="
            ml-5
            bg-secondary
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-fuchsia-800
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-fuchsia-500
          "
        >
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </form>
  <div class="border-t mt-4 py-6 px-4">
    <div>
      <h2 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("email setting") }}
      </h2>
      <p class="mt-1 text-sm text-gray-500">
        {{ $t("you can change your email and confirm here") }}
      </p>
    </div>
    <div class="col-span-12 sm:col-span-6 mt-5">
      <div class="flex justify-between">
        <label class="text-sm font-medium text-gray-700">{{
          $t("Email address")
        }}</label>
        <div class="flex items-center">
          <label
            class="text-xs font-medium text-white p-1 mr-1 rounded-md"
            :class="verifyEmail == true ? 'bg-green-400' : 'bg-red-400'"
            >{{
              verifyEmail == true ? $t("verified") : $t("not verified")
            }}</label
          >
          <!-- <button class="mr-1 bg-secondary text-white p-1 rounded-md text-xs">
            {{ $t("confirm") }}
          </button> -->
        </div>
      </div>
      <input
        type="text"
        v-model="user.email"
        class="
          mt-1
          block
          w-full
          border border-gray-300
          rounded-md
          shadow-sm
          py-2
          px-3
          focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
          sm:text-sm
        "
      />
    </div>
    <div class="pt-4">
      <div class="mt-4 py-4 flex justify-end">
        <button
          @click.prevent="confirmEmail"
          class="
            ml-5
            bg-secondary
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-fuchsia-800
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-fuchsia-500
          "
        >
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </div>
  <div class="border-t mt-4 py-6 px-4">
    <div>
      <h2 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("password setting") }}
      </h2>
      <p class="mt-1 text-sm text-gray-500">
        {{ $t("you can change your password here") }}
      </p>
    </div>
    <form @submit.prevent="changePassword">
      <div class="mt-6 grid grid-cols-12 gap-6 items-center">
        <div class="col-span-12 sm:col-span-6">
          <label
            for="password"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("New Password") }}</label
          >
          <input
            type="password"
            name="password"
            id="password"
            v-model="password"
            class="
              mt-1
              block
              w-full
              border border-gray-300
              rounded-md
              shadow-sm
              py-2
              px-3
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
            "
          />
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label
            for="password_confrimation"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("New Password Confrimation") }}</label
          >
          <input
            type="password"
            name="password_confrimation"
            id="password_confrimation"
            v-model="password_confrimation"
            class="
              mt-1
              block
              w-full
              border border-gray-300
              rounded-md
              shadow-sm
              py-2
              px-3
              focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
              sm:text-sm
            "
          />
        </div>
      </div>
      <div class="pt-4">
        <div class="mt-4 py-4 flex justify-end">
          <button
            type="submit"
            class="
              ml-5
              bg-secondary
              border border-transparent
              rounded-md
              shadow-sm
              py-2
              px-4
              inline-flex
              justify-center
              text-sm
              font-medium
              text-white
              hover:bg-fuchsia-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            {{ $t("change") }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="flex justify-between border-t mt-4 py-6 px-4">
    <div>
      <h2 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("account deletation") }}
      </h2>
    </div>
    <!-- <label
      for="last-name"
      class="block text-sm font-medium text-gray-700"
    >want to delete your account?</label> -->
    <button
      type="button"
      @click="deleteUserModal = true"
      class="
        ml-5
        bg-red-700
        border border-transparent
        rounded-md
        shadow-sm
        py-2
        px-4
        inline-flex
        justify-center
        text-sm
        font-medium
        text-white
        hover:bg-red-800
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
      "
    >
      {{ $t("delete") }}
    </button>
    <div
      v-if="deleteUserModal"
      class="
        fixed
        px-6
        rounded-md
        top-0
        right-0
        w-full
        h-full
        z-10
        inset-0
        overflow-y-auto
        bg-primary bg-opacity-50
      "
    >
      <div class="flex flex-col justify-center items-center w-full h-full z-10">
        <div
          class="
            flex flex-col
            bg-secondary
            h-24
            rounded-md
            w-96
            p-3
            z-20
            justify-between
            text-white
          "
        >
          <p class="text-center">
            {{ $t("Attention: All data will be permanently deleted !!") }}
          </p>
          <div class="flex items-center justify-between">
            <button
              @click="deleteUser"
              class="flex items-center bg-white text-secondary px-3 rounded-md"
            >
              {{ $t("yes") }}
            </button>
            <button
              @click="deleteUserModal = false"
              class="bg-white text-secondary px-3 rounded-md"
            >
              {{ $t("no") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const lands = ["Italien", "Österreich", "Deutschland", "Schweiz", "Anderes"];
const alterList = [
  { name: "bis 18 Jahre", id: 1 },
  { name: "19 - 35 Jahre", id: 2 },
  { name: "36 - 65 Jahre", id: 3 },
  { name: "über 65 Jahre", id: 4 },
];
const personList = [
  { name: "1", id: 1 },
  { name: "2", id: 2 },
  { name: "3", id: 3 },
  { name: "5 and more", id: 5 },
];
const childrenList = [
  { name: "0", id: 0 },
  { name: "1", id: 1 },
  { name: "2", id: 2 },
  { name: "3 and more", id: 3 },
];
const OccupationList = [
  { name: "Arbeiter/Arbeiterin", id: "ARB" },
  { name: "Angestellte/r", id: "ANG" },
  { name: "Führungskraft", id: "FUH" },
  { name: "Selbstständige/r", id: "SEL" },
  { name: "Unternehmer/in", id: "UNT" },
  { name: "Freiberufler/in", id: "FRE" },
  { name: "Rentner/in", id: "REN" },
  { name: "Anderer", id: "AND" },
];
export default {
  data() {
    return {
      lands,
      alterList,
      personList,
      childrenList,
      OccupationList,
      password: null,
      password_confrimation: null,
      verifyEmail: true,
      deleteUserModal: false,
      language: localStorage.getItem("haushaltsLang")
        ? localStorage.getItem("haushaltsLang")
        : "de",
    };
  },
  watch: {
    user() {
      if (
        this.user.email_verified_at === null ||
        this.user.email_verified_at === false
      ) {
        this.verifyEmail = false;
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.User.user;
    },
    provinces() {
      return this.$store.state.User.provinces.data;
    },
  },
  methods: {
    updateProfile() {
      let user = {
        active: this.user.active,
        alter: this.user.alter,
        children: this.user.children,
        created_at: this.user.created_at,
        deleted_at: this.user.deleted_at,
        id: this.user.id,
        income: this.user.income,
        job: this.user.job,
        land: this.user.land,
        lang: this.user.lang,
        local_community: this.user.local_community,
        login_date: this.user.login_date,
        perms: this.user.perms,
        person: this.user.person,
        person_a: this.user.person_a,
        province: this.user.province,
        updated_at: this.user.updated_at,
        username: this.user.username,
      };
      this.$store.dispatch("User/updateProfile", user).then((res) => {
        this.emitter.emit("showNotif", {
          type: res.type,
          msg: res.msg,
          showNotif: true,
        });
      });
    },
    changePassword() {
      if (this.password != this.password_confrimation) {
        this.emitter.emit("showNotif", {
          type: "error",
          msg: "password doesn't matcht",
          showNotif: true,
        });
        return;
      }
      this.$store
        .dispatch("User/changePassword", { password: this.password })
        .then((res) => {
          this.emitter.emit("showNotif", {
            type: res.type,
            msg: res.msg,
            showNotif: true,
          });
        });
    },
    deleteUser() {
      this.$store.dispatch("User/deleteAccount").then((res) => {
        this.emitter.emit("showNotif", {
          type: res.type,
          msg: res.msg,
          showNotif: true,
        });
        this.$router.push("/");
      });
    },
    GetProvinces() {
      this.$store.dispatch("User/GetProvinces");
    },
    confirmEmail() {
      this.$store
        .dispatch("User/confirmEmail", {
          email: this.user.email,
          language: this.language,
        })
        .then((res) => {
          localStorage.removeItem(`verify${this.user.id}`);
          this.emitter.emit("showNotif", {
            type: res.type,
            msg:
              res.type == "success"
                ? this.$t(res.msg)
                : this.$t(res.msg.email[0]),
            showNotif: true,
          });
        });
    },
  },
  mounted() {
    this.$store.dispatch("User/fetchUser");
    this.GetProvinces();

    if (
      this.user.email_verified_at === null ||
      this.user.email_verified_at === false
    ) {
      this.verifyEmail = false;
    } else {
      this.verifyEmail = true;
    }
  },
};
</script>

<style></style>
